import React, { useState } from 'react';
import { useProfiles, inviteUser, ProfilesQueryParams, Profile } from 'mushin-redux-store';
import i18n from 'i18next';
import SortBySelect from '../FiltersToolbar/Elements/SortBySelect';
import FiltersToolbar from '../FiltersToolbar/FiltersToolbar';
import { criteriaParamsFilter, groupsFilter, memberStatusFilter } from '../FiltersToolbar/helpers';
import InputText from '../InputText/InputText';
import ActionBar from '../ActionBar/ActionBar';
import { openAlert, openModal } from '../../Redux/reducers/modalsSlice';
import { addNotifAction, addNotifSuccess } from '../../Redux/reducers/appNotificationsSlice';
import { useAppDispatch, useAppSelector } from '../../Helpers/hooks';
import { usersExport } from '../../Helpers/excel/usersExport';
import { useTextInputWithDelay } from '../../Helpers/FormsHelpers/hooks';

export type MembersSearchParams = {
    is_contributor?: string;
    sort?: string;
    name_search?: string;
    groups?: string[];
    criteria?: string[];
    status?: string;
};

type Props = React.PropsWithChildren<{
    searchParams: MembersSearchParams;
    setSearchParams: (searchParams: MembersSearchParams) => void;
    queryParams: ProfilesQueryParams;
    removeUsers?: (ids: string[]) => Promise<void>;
    selectedIds?: string[];
    setSelectedIds?: (ids: string[]) => void;
    getStatus: (user: Profile) => { mod: string; label: string; date?: string };
    projectId?: string;
    contributors?: boolean;
}>;

const RE_INVITE_ACTION_ID = 'RE_INVITE_USERS';

const UsersToolbar: React.FC<Props> = ({
    searchParams,
    setSearchParams,
    queryParams,
    removeUsers,
    selectedIds,
    setSelectedIds,
    getStatus,
    projectId,
    children,
    contributors = false,
}) => {
    const reInviteInProgress = useAppSelector((state) =>
        state.appNotifications.some((item) => item.id === RE_INVITE_ACTION_ID),
    );
    const [exportProgress, setExportProgress] = useState(-1);
    const [query, setQuery] = useTextInputWithDelay(
        (value) => setSearchParams({ ...searchParams, name_search: value }),
        1,
    );
    const selectedItems = useProfiles(selectedIds);

    const dispatch = useAppDispatch();

    const sendInvitations = async (): Promise<void> => {
        if (selectedItems) {
            dispatch(
                addNotifAction(RE_INVITE_ACTION_ID, i18n.t('administration.members.resendInvite'), async () => {
                    const emails = [];
                    for (const profile of selectedItems) {
                        if (profile?.user?.email && !profile.metrics.first_activity_at) {
                            emails.push(profile.user.email);
                        }
                    }
                    const result = await dispatch(
                        inviteUser(emails, { reInvite: true, isContributor: contributors, projectId }),
                    );
                    dispatch(addNotifSuccess(i18n.t('administration.members.invitesSent', { number: result.length })));
                }),
            );
        }
    };

    const handleRemoveUsers = () => {
        dispatch(
            openAlert({
                type: 'danger',
                title: i18n.t('administration.members.removeUsersAlert.title'),
                icon: 'Warning',
                confirmLabel: i18n.t('global.confirm'),
                body: i18n.t('administration.members.removeUsersAlert.content'),
                confirmAction: async () => {
                    if (setSelectedIds && selectedIds) {
                        await removeUsers?.(selectedIds);
                        setSelectedIds([]);
                    }
                },
            }),
        );
    };

    const selectionActive = !!selectedIds?.length;
    const atLeastOneInactiveSelected = selectedItems
        ? selectedItems.some((item) => item && !item.metrics.first_activity_at)
        : false;

    return (
        <section className="mu-toolbar">
            <div className="mu-toolbar__search">
                <InputText
                    classModifiers={['full-width', 'toolbar']}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            <SortBySelect
                sort={searchParams.sort}
                setSort={(sort) => setSearchParams({ ...searchParams, sort })}
                options={[
                    { key: undefined, icon: 'Calendar', label: i18n.t('components.sort.mostRecent') },
                    { key: 'email', icon: 'ArrowDown', label: i18n.t('components.sort.alphabetic') },
                    { key: '-email', icon: 'ArrowUp', label: i18n.t('components.sort.reverseAlphabetic') },
                ]}
            />
            <FiltersToolbar
                filters={{
                    status: memberStatusFilter(
                        searchParams.status,
                        (status) => setSearchParams({ ...searchParams, status }),
                        !!(queryParams.project_id || queryParams.album_id),
                    ),
                    groups: groupsFilter(searchParams.groups, (groups) => setSearchParams({ ...searchParams, groups })),
                    ...(contributors && {
                        criteria: criteriaParamsFilter(searchParams.criteria, (criteria) =>
                            setSearchParams({ ...searchParams, criteria }),
                        ),
                    }),
                }}
                resetAll={() => setSearchParams({ is_contributor: searchParams.is_contributor })}
            />
            <ActionBar
                items={[
                    {
                        icon: 'Stats',
                        label: i18n.t('criteria.items'),
                        handler: () => {
                            dispatch(openModal('ProfilesStats', { queryParams }));
                        },
                        hidden: !contributors,
                    },
                    {
                        icon: 'Redo',
                        label: i18n.t('administration.members.resendInvite'),
                        handler: () => {
                            if (selectedItems) {
                                sendInvitations().then();
                            }
                        },
                        disabled: !atLeastOneInactiveSelected,
                        hidden: !selectionActive,
                        progress: reInviteInProgress,
                    },
                    {
                        icon: 'Export',
                        label: i18n.t('global.export'),
                        handler: () => {
                            dispatch(
                                usersExport(
                                    selectedIds || [],
                                    { ...searchParams, project_id: projectId },
                                    setExportProgress,
                                    getStatus,
                                ),
                            ).then(() => setExportProgress(-1));
                        },
                        hidden: !selectionActive,
                        progress: exportProgress,
                    },
                    {
                        icon: 'Delete',
                        label: i18n.t('global.delete'),
                        handler: () => {
                            handleRemoveUsers();
                        },
                        hidden: !selectionActive || !removeUsers,
                    },
                ]}
            />
            {children}
        </section>
    );
};

export default UsersToolbar;
